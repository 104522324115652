<template  lang="pug" >
    div(class="")
        v-row 
            v-col(cols="12")
                v-stepper(v-model="stepper")
                    v-stepper-header(outlined)
                        //- v-stepper-step(:complete="stepper > 1" step="1") 
                        //- v-divider
                        v-stepper-step(:complete="stepper > 1" step="1")
                        v-divider
                        v-stepper-step(:complete="stepper > 2" step="2")
                        v-divider
                        v-stepper-step(:complete="stepper > 3" step="3") 
                    v-stepper-items(outlined)
                        //- Anterior paso 1. Se descarta en el nuevo tomor
                        //- v-stepper-content(step="1")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Email - Comercial
                            ValidationObserver(ref="formStep1")
                                form(@submit.prevent="handleNextStep1")
                                    v-card(class="mb-4" outlined)
                                        v-card-text
                                            v-row 
                                                v-col(cols="6" )
                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="blue-500--text text-body-2 mb-4") Título de la campaña
                                                            p( class="text-body-2 mb-0") Debe escribirse tal cual el nombre que viene en el documento de 2023 Contenido emailing.
                                                            v-row(class="align-baseline")
                                                                v-col(cols="11")
                                                                    ValidationProvider(v-slot='{ errors }' name='título de la campaña' rules='required')
                                                                        v-text-field(label='Título de la campaña' v-model='form.campName' :error-messages='errors' )
                                                                v-col(cols="1")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span El motor detecta automáticamente el layout y contenido del correo <br> al encontrar coincidencia exacta con el nombre que se encuentra en el encabezado del layout.
                                                        v-col(cols="12")
                                                            p( class="blue-500--text text-body-2 mb-5") Semana del año en curso (2023)
                                                            ValidationProvider(v-slot='{ errors }' name='semana del año' rules='required')
                                                                v-select( 
                                                                        :items="semanaEnCursoList" 
                                                                        item-text="text"
                                                                        item-value="value"
                                                                        label="Selecciona una opcion"
                                                                        v-model='form.semana'
                                                                        :error-messages='errors'
                                                                        :hint="`Semana ${form.semana}`")
                                                        v-col(cols="12")
                                                            p( class="blue-500--text text-body-2 mb-4") Selecciona el layout
                                                            p( class="text-body-2 mb-0") Para la semana 1 a 25 utilizar layout 0, a partir de la semana 26 se recomienda utilizar layout 1
                                                            v-row(class="align-baseline")
                                                                v-col(cols="11")
                                                                    ValidationProvider(v-slot='{ errors }' name='layout' rules='required')
                                                                        div(class="")
                                                                            v-radio-group(v-model="form.tipoLayout" :error-messages='errors' @change="()=>form.espacios=null")
                                                                                v-radio(label="Layout 0" value=0)
                                                                                v-radio(label="Layout 1" value=1)
                                                                v-col(cols="1")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span Para la semana 1 a 25 utilizar layout 0, a partir de la semana 26 se recomienda utilizar layout 1
                                                        v-col(cols="12" v-if="form.tipoLayout == '1'")
                                                            p( class="blue-500--text text-body-2 mb-4") Número de espacios en el layout
                                                            p( class="text-body-2 mb-0") Escribir el número entero de espacios con los que cuenta el layout del correo, incluya en el conteo los banners, complementarios y cintillos.
                                                            v-row(class="align-baseline")
                                                                v-col(cols="11")
                                                                    ValidationProvider(v-slot='{ errors }' name='número de espacios' rules='required')
                                                                        v-text-field(label='Espacios' type="number" v-model='form.espacios' :error-messages='errors' )
                                                v-col(cols="4" offset-sm="0")
                                                    v-row 
                                                        v-col(cols="12")
                                                            p(class="text-body-2 mb-2") Para obtener el título de la campaña puedes consultar el siguiente dicumento.
                                                            div(class="mb-1") <v-btn href="https://docs.google.com/spreadsheets/d/1z2Q9OIq0FcOwi-pQdblPmVDjvkgGdZiEbGl7fnCm8vk/edit#gid=1383119032" target="_blank" x-small color="success">Abrir documento <v-icon right size="13">mdi-open-in-new</v-icon></v-btn>  
                                                            v-dialog(
                                                                v-model="dialogSheetContenidoEmail"
                                                            )
                                                                template(v-slot:activator="{ on, attrs }")
                                                                    v-btn(
                                                                        color="primary"
                                                                        dark
                                                                        x-small
                                                                        outlined
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ) Mostrar documento
                                                                        v-icon(right size="13") mdi-eye-outline
                                                                v-card
                                                                    v-card-text
                                                                        div(class="")
                                                                            iframe(
                                                                                height="450"
                                                                                style="width:100%;"
                                                                                frameborder="0" allowfullscreen
                                                                                src='https://docs.google.com/spreadsheets/d/1z2Q9OIq0FcOwi-pQdblPmVDjvkgGdZiEbGl7fnCm8vk' )

                                    v-btn(text x-small @click="$emit('onClickSelectStep', STEPS.GO_TO_EMAIL)" class="mr-2") regresar
                                    v-btn( color="primary" type="submit" x-small) siguiente
                        //- paso 1
                        v-stepper-content(step="1")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Email - Comercial
                            ValidationObserver(ref="formStep1")
                                form(@submit.prevent="handleNextStep1")
                                    v-card(class="mb-4" outlined)
                                        v-card-text
                                            v-row 
                                                v-col(cols="6")
                                                    h3(class="blue-500--text text-body-2 mb-2 font-weight-bold") Completa los datos para construir el nombre de control de campaña y lógicas
                                                    p(class="text-caption  mb-0") *Todos los campos son obligatorios y para tener un control interno de la campaña, independiente del nombre en el layout.
                                            v-row 
                                                v-col(cols="6")
                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="text-body-2 mb-0") Escriba el título de la campaña, sin espacios ni caracteres especiales: ej. RegalosParaElla
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10")
                                                                    ValidationProvider(v-slot='{ errors }' name='título de la campaña' rules='required')
                                                                        v-text-field(label='Título de la campaña' v-model='form.campNameInt' :error-messages='errors')
                                                                v-col(cols="2")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span El nombre debe ir sin espacios ni caracteres especiales como acentos y tildes
                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="text-body-2 mb-0") Escriba fecha de lanzamiento <br> Formato DDMMAAAA: ej 24012023
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10")
                                                                    v-menu(
                                                                    :close-on-content-click="false"
                                                                    :nudge-right="40"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    min-width="auto")
                                                                        template(v-slot:activator='{ on, attrs }')
                                                                            ValidationProvider(v-slot='{ errors }' name='fecha de lanzamiento' rules='required')
                                                                                v-text-field(
                                                                                    label='Fecha' 
                                                                                    v-model='form.fechaConsulta'  
                                                                                    :error-messages='errors'
                                                                                    prepend-icon="mdi-calendar"
                                                                                    v-bind="attrs"
                                                                                    v-on="on")
                                                                        v-date-picker(
                                                                            v-model="datePickerFechaLanzamiento"
                                                                            @change="handleChangeFechaLanzamiento"
                                                                            @input="menu = false")
                                                                v-col(cols="2")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span El formato es día, mes, año, con dos, dos y cuatro dígitos, cuando tengamos mes o día de un sólo dígito colocar un cero a la izquierda.

                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="text-body-2 mb-0") Escriba límite de similaridad (opcional):
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10")
                                                                    ValidationProvider(v-slot='{ errors }' name='similaridad' rules='required')
                                                                        v-text-field(label='Similaridad' v-model='form.similaridad' type="number" step="0.1" min="0.1" max="1"  :error-messages='errors')
                                                                v-col(cols="2")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span 
                                                                            | Elegir entre 0.5 y 0.9,  entre más alto más restrictivo es el radio de coincidencia entre las palabras clave y los niveles de categoría del catálogo Coppel.  <br>
                                                                            | Por default utiliza 0.8 que ha sido utilizado en pruebas y ha demostrado ser adecuado. <br>
                                                                            | Impacta sólo sobre las lógicas. Dejar en blanco si no se quiere modificar el valor por default.

                                                    template()
                                                        v-row 
                                                            v-col(cols="10")
                                                                p( class="text-body-2") Otros filtros
                                                                v-divider(class='mb-3')

                                                        v-row 
                                                            v-col(cols="10")
                                                                p( class="text-body-2") Si no está de acuerdo con los resultado obtenidos puede reducir el límite de similaridad para encontrar más coincidencias o aumentarlo para encontrar menos. <br/> Tambien puede justar los siguientes campos.

                                                        
                                                        v-row 
                                                            v-col(cols="12")
                                                                p( class="text-body-2 mb-0") Categorías complementarias:
                                                                v-row(class="align-baseline")
                                                                    v-col(cols="10")
                                                                        ValidationProvider(v-slot='{ errors }' name='categorias')
                                                                            v-autocomplete( 
                                                                                :items="inputCategoryItems" 
                                                                                label="Selecciona una o varias opciones."
                                                                                v-model='form.categorias'
                                                                                multiple 
                                                                                chips
                                                                                clearable
                                                                                :disabled="inputCategoryItems.length == 0"
                                                                                @change="onChangeCategorias"
                                                                                :error-messages='errors')

                                                        v-row 
                                                            v-col(cols="12")
                                                                p( class="text-body-2 mb-0") subcategorías complementarias:
                                                                v-row(class="align-baseline")
                                                                    v-col(cols="10")
                                                                        ValidationProvider(v-slot='{ errors }' name='subcategorias')
                                                                            v-autocomplete( 
                                                                                :items="inputSubcategoryItems" 
                                                                                label="Selecciona una o varias opciones."
                                                                                v-model='form.subcategorias'
                                                                                multiple
                                                                                clearable
                                                                                small-chips
                                                                                @change="onChangeSubcategorias"
                                                                                :disabled="inputSubcategoryItems.length == 0"
                                                                                :error-messages='errors')

                                                        v-row 
                                                            v-col(cols="12")
                                                                p( class="text-body-2 mb-0") clases complementarias:
                                                                v-row(class="align-baseline")
                                                                    v-col(cols="10")
                                                                        ValidationProvider(v-slot='{ errors }' name='clases')
                                                                            v-autocomplete( 
                                                                                :items="inputClaseItems" 
                                                                                label="Selecciona una o varias opciones."
                                                                                v-model='form.clases'
                                                                                multiple
                                                                                clearable
                                                                                small-chips
                                                                                :disabled="inputClaseItems.length == 0"
                                                                                :error-messages='errors')

                                                    //- v-row 
                                                        v-col(cols="12")
                                                            template()
                                                                v-alert(type="success" v-if="jobState == 'success'") Petición procesada con éxito. Puedes ver tus los resultados haciendo clic en "Abrir documento".
                                                                v-alert(type="error" v-if="jobState == 'failed'") Ha ocurrio un erro en el DAG.
                                                                    v-btn(href="https://ja5fdcbfa032e6fd7p-tp.appspot.com/tree?dag_id=dag_dev_motor_logicas&root=" target="_blank" x-small class="ml-2" color="warning") Ver DAG  <v-icon right size="13">mdi-open-in-new</v-icon>
                                                            v-btn( color="success"  x-small type="submit" :disabled="formLoading" :loading="formLoading") Calcular lógica
                                                v-col(cols="4" )
                                                    v-row()
                                                        v-col(cols="12")
                                                            h3(class="font-weight-bold text-body-2 mb-2") Consulta los documentos
                                                            p(class="text-body-2 mb-2") * Copia una semana del documento original y pegala en el documento interno
                                                            div(class="mb-2") <v-btn href="https://docs.google.com/spreadsheets/d/16zg3ulDGJgRPgm3oywtxZ7ZK8DIw_Wdm4gUlvpJLkWk/edit?gid=1413156345#gid=1413156345" target="_blank" x-small color="success">Abrir documento original<v-icon right size="13">mdi-open-in-new</v-icon></v-btn>
                                                            div(class="mb-1") <v-btn href="https://docs.google.com/spreadsheets/d/1op0OxcBXVMzDz3pe07eWK4jSJQpnPcMUqLJuNRlGPDQ/edit?gid=272920683#gid=272920683" target="_blank" x-small color="success">Abrir documento interno<v-icon right size="13">mdi-open-in-new</v-icon></v-btn> 
                                                        //- v-col(cols="12")
                                                            h3(class="font-weight-bold text-body-2 mb-2") Estos son los niveles de producto calculados por el motor
                                                            div(class="mb-4")
                                                                div(class="mb-1") <v-btn href="https://docs.google.com/spreadsheets/d/1ov-VHnXjXWuugJl85OdvFPAihujuAa4ffRSdktpv990/edit#gid=629300663" target="_blank" x-small color="success">Abrir documento <v-icon right size="13">mdi-open-in-new</v-icon></v-btn>  
                                                                v-dialog(
                                                                    v-model="dialogSheetContenidoEmail"
                                                                )
                                                                    template(v-slot:activator="{ on, attrs }")
                                                                        v-btn(
                                                                            color="primary"
                                                                            dark
                                                                            x-small
                                                                            outlined
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        ) Mostrar documento
                                                                            v-icon(right size="13") mdi-eye-outline
                                                                    v-card
                                                                        v-card-text
                                                                            div(class="") <iframe height="450" style="width:100%;" frameborder="0" allowfullscreen src='https://docs.google.com/spreadsheets/d/1ov-VHnXjXWuugJl85OdvFPAihujuAa4ffRSdktpv990/edit#gid=629300663' />
                                                            //- div 
                                                            //-     p(class="text-body-2 mb-2") ¿Estás de acuerdo con esta lógica?
                                                            //-         v-tooltip( top )
                                                            //-             template( v-slot:activator="{ on }")
                                                            //-                 v-btn( icon v-on="on" x-small class="blue-600 ml-2")
                                                            //-                     v-icon( color="white") mdi-help
                                                            //-             span Si no está de acuerdo puede reducir el límite de similaridad para encontrar más coincidencias o aumentarlo para encontrar menos.
                                                            //-     v-btn( color="primary" class="mr-3"  x-small @click="stepper = 3" :disabled='!canGoToStep3') Si
                                                            //-     v-btn( color="default" class="mr-3"  x-small  :disabled='!canGoToStep3') No
                                        
                                    v-btn(text x-small @click="$emit('onClickSelectStep', STEPS.GO_TO_EMAIL)" class="mr-2") regresar
                                    v-btn( color="primary" type="submit" x-small) continue
                        //- paso 2
                        v-stepper-content(step="2")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Email - Comercial
                            ValidationObserver(ref="formStep2")
                                form(@submit.prevent="handleGenerarAudiencia")
                                    v-card(class="mb-4" outlined)
                                        v-card-text
                                            v-row 
                                                v-col(cols="6")
                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="text-body-2 mb-0") Escriba el número de días para consultar vistas y búsquedas (opcional):
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10")
                                                                    ValidationProvider(v-slot='{ errors }' name='número de días' rules='required')
                                                                        v-text-field(label='Número de días' v-model='form.intervaloDias' type="number" min="1" :error-messages='errors')
                                                                v-col(cols="2")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span Es el número de días naturales para vistas y búsquedas, contando a partir del día de la consulta. Ejemplo, si se requieren vistas y búsquedas a un mes escribir 31. Por default son 20 días.
                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="text-body-2 mb-0") Escriba el límite inferior de propensión (opcional):
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10")
                                                                    ValidationProvider(v-slot='{ errors }' name='similaridad' rules='required')
                                                                        v-select( 
                                                                            :items="propension" 
                                                                            item-text="text"
                                                                            item-value="value"
                                                                            label="Propensión"
                                                                            v-model='form.propension'
                                                                            :hint="`Propensión ${form.propension}`"
                                                                            :error-messages='errors'
                                                                            chips)
                                                                v-col(cols="2")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span Es el límite inferior de propensión a utilizar, elegir entre 0.8 y 1. Entre menor sea el número, traeremos clientes menos propensos pero aumentará el volumen. Por default se utilizan las reglas comerciales.
                                                    v-row 
                                                        v-col(cols="12")
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10" class="pt-0 pb-0")
                                                                    ValidationProvider(v-slot='{ errors }' name='layout' rules='required')
                                                                        div(class="")
                                                                            v-radio-group(v-model="form.reglasPredefinidas" :error-messages='errors' class="mt-0")
                                                                                v-radio(label="Reglas comerciales" :value="true")
                                                                                v-radio(label="Propensiones modificada" :value="false")
                                                    
                                                    v-divider

                                                    v-row 
                                                        v-col(cols="12")
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10" class="pt-0 pb-0")
                                                                    ValidationProvider(v-slot='{ errors }' name='flag altas' rules='required')
                                                                        div(class="")
                                                                            v-radio-group(v-model="form.flagAltas" :error-messages='errors' class="mt-0")
                                                                                v-radio(label="Temporalidad Alta" :value="true")
                                                                                v-radio(label="Temporalidad Baja" :value="false")

                                                
                                                    v-row 
                                                        v-col(cols="12")
                                                            p( class="text-body-2 mb-0") Temporalidad extra
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10")
                                                                    ValidationProvider(v-slot='{ errors }' name='temporalidad' rules='validate_MM_YYYY')
                                                                        v-text-field(label='MM AAAA' v-model='form.temporalidades_ordenes_extra' :error-messages='errors')
                                                                v-col(cols="2")
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600")
                                                                                v-icon( color="white") mdi-help
                                                                        span Temporalidad extra en formato MM AAAA. Para más de una separar por ,
                                                    v-divider(class="mb-4")
                                                    v-row 
                                                        v-col(cols="12" class="pb-0")
                                                            p( class="text-body-2 mb-2") Excluir a clientes con compras de clase en el último mes
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10" class="pb-0")
                                                                    v-checkbox(label='Excluir cliente' v-model='form.excluirCompras' dense  class="pt-0 mt-0 pb-0 mb-0")

                                                    v-row 
                                                        v-col(cols="12" class="pb-0")
                                                            v-row(class="align-baseline")
                                                                v-col(cols="10" class="pb-0")
                                                                    v-checkbox(label='Sembrar correos' v-model='form.flagSembrar' dense  class="pt-0 mt-0 pb-0 mb-0")

                                                    v-row 
                                                        v-col(cols="12")
                                                            v-row(class="align-baseline" )
                                                                v-col(cols="10" class="pb-0")
                                                                    v-checkbox(label='Dividir Segmento por porcentaje:' v-model='form.flagDividir' dense class="pt-0 mt-0 pb-0 mb-0" )
                                                                v-col(cols="10" class="pt-0" )
                                                                    p( class="text-body-2 mb-2" :class="{'grey--text': !form.flagDividir}") <b>Testigo:</b> {{ form.por_testigo }}% <br> <b>Piloto:</b> {{ 100 - form.por_testigo }}%
                                                                    v-slider(step="1" max="100" min="1"  v-model='form.por_testigo' :disabled="!form.flagDividir")

                                                                
                                                    v-row 
                                                        v-col(cols="12")
                                                            template()
                                                                v-alert(type="success" v-if="jobState == 'success' && stepper == 2") Petición procesada con éxito. Puedes descargar los resultados haciendo clic en el botón continuar.
                                                                v-alert(type="error" v-if="jobState == 'failed' && stepper == 2") Ha ocurrio un erro en el DAG.
                                                                    v-btn(href="https://a3580af07e05498d982779859d4f535a-dot-us-central1.composer.googleusercontent.com/dags/dag_motor_comercial/grid?search=dag_motor_comercial=" target="_blank" x-small class="ml-2" color="warning") Ver DAG  <v-icon right size="13">mdi-open-in-new</v-icon>
                                                            v-btn( color="success"  x-small type="submit" :disabled="formLoading" :loading="formLoading") Generar audiencia
                                                v-col(cols="4")
                                                    v-row()
                                                        v-col(cols="12")
                                                            h3(class="font-weight-bold text-body-2 mb-2") Resultados de audiencias
                                                            div(class="mb-4")
                                                                div(class="mb-1") <v-btn :href="linkVerArchivos" :disabled="jobState == null" target="_blank" x-small color="success">Ver archivos <v-icon right size="13">mdi-eye-outline</v-icon></v-btn>
                                                            //- div 
                                                                p(class="text-body-2 mb-2")
                                                                    | Ver los archivos en Cloud Storage

                                                            //-div 
                                                                p(class="text-body-2 mb-2") ¿Estas de acuerdo con el volumen total de la audiencia?
                                                                    v-tooltip( top )
                                                                        template( v-slot:activator="{ on }")
                                                                            v-btn( icon v-on="on" x-small class="blue-600 ml-2")
                                                                                v-icon( color="white") mdi-help
                                                                        span 
                                                                            | Si no está de acuerdo con el volumen: <br>
                                                                            | Para aumentar audiencia: aumentar el número de días en vistas y búsquedas y disminuir el límite inferior de propensión. <br>
                                                                            | Para disminuir audiencia: disminuir el número de días en vistas y búsquedas y aumentar el límite inferior de propensión
                                                                v-btn( color="primary" class="mr-3"  x-small) Si
                                                                v-btn( color="default" class="mr-3"  x-small) No
                                    
                            v-btn(text x-small @click="stepper = 1" class="mr-2") regresar
                            v-btn( color="primary" @click="stepper = 3" x-small :disabled="jobState != 'success'") continue
                        //- paso 3
                        v-stepper-content(step="3")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Email - Comercial
                            v-card(class="mb-4" outlined)
                                v-card-text
                                    v-row 
                                        v-col(cols="4")
                                            //-v-row 
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small disabled) Descargar audiencia para email
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small disabled) Descargar la lógica comercial
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small disabled) Bases para Postmortem
                                            v-row 
                                                v-col(cols="12" v-if="jobState == 'running' || jobState == 'failed'")
                                                    v-alert(type="warning" v-if="jobState == 'running' && stepper == 3") Espera a que termine de ejecutarse la petición para obetener tus links habilitados. 
                                                    v-alert(type="error" v-if="jobState == 'failed' && stepper == 3") Ha ocurrio un erro en el DAG. No pudimos generar tus archivos de descarga.
                                                        v-btn(href="https://a3580af07e05498d982779859d4f535a-dot-us-central1.composer.googleusercontent.com/dags/dag_motor_comercial/grid?search=dag_motor_comercial=" target="_blank" x-small class="ml-2" color="warning") Ver DAG  <v-icon right size="13">mdi-open-in-new</v-icon>
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small target="_blank" :href="linkVerArchivos" :loading="jobState == 'running'" :disabled="jobState == 'running' || jobState == null" ) Ver archivos
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small target="_blank" :href="linkArchivoFinal" :loading="jobState == 'running'" :disabled="jobState == 'running' || jobState == null") Archivo final
                                                v-col(cols="12")
                                                    v-btn(color="primary"  x-small @click="stepper = 2" ) Regresar
                            v-btn(text x-small @click="handleBtnGenerarNuevaAudiencia" class="mr-2") Generar otra audiencia para email   
                            v-btn( color="primary" @click="handleBtnFinalizar" x-small) Finalizar
</template>
<script>
import { STEPS } from './index.vue';
// Service
import MotorAudienciasService from '../../../services/MotorAudienciasService'
const motorAudienciasService = new MotorAudienciasService();
export default {
    name: 'MedioSelectedEmailComercial',
    data() {
        return {
            STEPS,
            stepper: 1,
            form: {
                // form motor
                // semana: '', // s campo deprecado
                // campName: '', // camp_name campo deprecado
                campNameInt: '', // camp_name_int
                fechaConsulta: '', // fec_consulta ddmmaaaa
                // tipoLayout: null, // tipo_layout deprecado
                // espacios: null, // deprecado
                similaridad: 0.8, // lim_sim
                categorias: [],
                subcategorias: [],
                clases: [],
                intervaloDias: 20, // intervalo_dias
                propension: '>=0.8', // prop_vista, prop_busqueda, prop_compra
                excluirCompras: true, // excluir_compras,
                flagDividir: true,
                por_testigo: 90,
                flagSembrar: true,
                reglasPredefinidas: true, // reglas_predefinidas
                flagAltas: true,
                temporalidades_ordenes_extra: '',
                // configuraciones
                folder: '', // nombre del folder dentro del bucket
            },
            formLoading: false,
            // semanaEnCursoList: [ campo deprecado
            //     {text: 'Semana 01 (02 al 08 de Enero 2022)', value: '01'},
            //     {value: '02', text: 'Semana 02 (09 al 15 de Enero 2022)'},
            //     {value: '03', text: 'Semana 03 (16 al 22 de Enero 2022)'},
            //     {value: '04', text: 'Semana 04 (23 al 29 de Enero 2022)'},
            //     {value: '05', text: 'Semana 05 (30 de Enero al 05 de Febrero 2023)'},
            //     {value: '06', text: 'Semana 06 (06 al 12 de Febrero 2023)'},
            //     {value: '07', text: 'Semana 07 (13 al 19 de Febrero 2023)'},
            //     {value: '08', text: 'Semana 08 (20 al 26 de Febrero 2023)'},
            //     {value: '09', text: 'Semana 09 (27 de Febrero al 05 de Marzo 2023)'},
            //     {value: '10', text: 'Semana 10 ( 06 al 12 de Marzo 2023)'},
            //     {value: '11', text: 'Semana 11 (13 al 19 de Marzo 2023)'},
            //     {value: '12', text: 'Semana 12 (20 al 26 de Marzo 2023)'},
            //     {value: '13', text: 'Semana 13 (27 de Marzo al 02 de Abril 2023)'},
            //     {value: '14', text: 'Semana 14 (03 de Abril al 09  2023)'},
            //     {value: '15', text: 'Semana 15 (10 al 16 de abril 2023)'},
            //     {value: '16', text: 'Semana 16 (17 al 23 de abril 2023)'},
            //     {value: '17', text: 'Semana 17 (24 al 30 de abril 2023)'},
            //     {value: '18', text: 'Semana 18 (01 al 07 de mayo)'},
            //     {value: '19', text: 'Semana 19 (08 al 14 de mayo )'},
            //     {value: '20', text: 'Semana 20 (15 al 21 de mayo)'},

            //     {value: '21', text: 'Semana 21 (22 al 28 de mayo)'},
            //     {value: '22', text: 'Semana 22 (29 de mayo al 04 de junio)'},
            //     {value: '23', text: 'Semana 23 (05 al 11 de junio)'},
            //     {value: '24', text: 'Semana 24 (12 al 18 de junio)'},
            //     {value: '25', text: 'Semana 25 (19 al 25 de junio)'},
            //     {value: '26', text: 'Semana 26 (26 de junio al 02 de julio)'},
            //     {value: '27', text: 'Semana 27 (03 al 09 de julio)'},
            //     {value: '28', text: 'Semana 28 (10 al 16 de julio)'},
            //     {value: '29', text: 'Semana 29 (17 al 23 de julio)'},
            //     {value: '30', text: 'Semana 30 (24 al 30 de julio)'},
            //     {value: '31', text: 'Semana 31 (31 de julio al 06 de agosto)'},
            //     {value: '32', text: 'Semana 32 (07 al 13 de agosto)'},
            //     {value: '33', text: 'Semana 33 (14 al 20 de agosto)'},
            //     {value: '34', text: 'Semana 34 (21 al 27 de agosto)'},
            //     {value: '35', text: 'Semana 35 (28 de agosto al 03 de septiembre)'},
            //     {value: '36', text: 'Semana 36 (04 al 10 de septiembre)'},

            //     {value: '37', text: 'Semana 37 (11 al 17 de septiembre)'},
            //     {value: '38', text: 'Semana 38 (18 al 24 de septiembre)'},
            //     {value: '39', text: 'Semana 39 (25 de septiembre al 01 de octubre)'},
            //     {value: '40', text: 'Semana 40 (02 al 08 de octubre)'},
            //     {value: '41', text: 'Semana 41 (09 al 15 de octubre)'},
            //     {value: '42', text: 'Semana 42 (16 al 22 de octubre)'},
            //     {value: '43', text: 'Semana 43 (23 al 29 de octubre)'},
            //     {value: '44', text: 'Semana 44 (30 de octubre al 05 de noviembre)'},
            //     {value: '45', text: 'Semana 45 (06 al 12 de noviembre)'},
            //     {value: '46', text: 'Semana 46 (13 al 19 de noviembre)'},
            //     {value: '47', text: 'Semana 47 (20 al 26 de noviembre)'},
            //     {value: '48', text: 'Semana 48 (27 de noviembre al 03 de diciembre)'},
            //     {value: '49', text: 'Semana 49 (04 al 10 de diciembre)'},
            //     {value: '50', text: 'Semana 50 (11 al 17 de diciembre)'},
            //     {value: '51', text: 'Semana 51 (18 al 24 de diciembre)'},
            //     {value: '52', text: 'Semana 52 (25 al 31 de diciembre)'},
            // ],
            propension: [
                {text: '0.5', value: '>=0.5'},
                {text: '0.6', value: '>=0.6'},
                {text: '0.7', value: '>=0.7'},
                {text: '0.8', value: '>=0.8'},
                {text: '0.9', value: '>=0.9'},
                {text: '1', value: '>=1'},
            ],
            // \
            datePickerFechaLanzamiento: (new Date()).toISOString().substr(0, 10),
            dialogSheetContenidoEmail: false,
            // taks dag
            jobSubmited: null, // setInterval
            jobState: null, // success - fail, queued, etc. extados de dag de airflow
            jobEndDate: null,
            // inputs data
            categoriasWithSubclasifitations: [],
            inputClaseItems: [],
            inputCategoryItems: [],
            inputSubcategoryItems: []
        }
    },
    watch: {
        jobEndDate: function (newJobEndDate, oldJobEndDate) {
            // si jobEndDate es diferente de null, el dag terminó
            if (newJobEndDate) {
                this.stopWatchingJobSubmited()
                this.formLoading = false; // 
            }
            if(newJobEndDate == null) {
                this.formLoading = true;
            }
        }
    },
    computed: {
        linkVerArchivos() {
            if (this.form.folder == '') {
                return "https://console.cloud.google.com/storage/browser/outputmodels/Motor-de-logicas-para-audiencias/COMERCIAL?project=ecommerce-b"
            }
            return `https://console.cloud.google.com/storage/browser/outputmodels/Motor-de-logicas-para-audiencias/COMERCIAL/${this.form.folder};tab=objects?project=ecommerce-bi`
        },
        linkArchivoFinal() {
            if (this.form.folder == '') {
                return "https://console.cloud.google.com/storage/browser/outputmodels/Motor-de-logicas-para-audiencias/COMERCIAL?project=ecommerce-b"
            }
            return `https://console.cloud.google.com/storage/browser/outputmodels/Motor-de-logicas-para-audiencias/COMERCIAL/${this.form.folder}/final?project=ecommerce-bi`
        },
    },
    methods: {
        submitForm() {
            // setear valores antes de eviar
            this.jobEndDate = null;
            this.jobState = null;
            this.formLoading = true;
            // form
            let formData = new FormData();
            Object.entries(this.form).forEach(([key, value]) => {
                if(value != undefined && value.length) {
                    if(Array.isArray(value) ) {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
                if (typeof value == 'number' || typeof value == 'boolean' ) {
                    formData.append(key, value);
                }
            });
            // send form
            motorAudienciasService
                .submitFormEmailComercial(formData)
                .then(response => {
                    let {state, dag_run_id} = response.data.data
                    this.jobState = state // queued
                    // monitorear estado del dag
                    this.startWatchingJobSubmited(dag_run_id)
                    // recoger el nombre de la carpeta donde se depositaran los archivos
                    if (response.data.data.folder) {
                        this.form.folder = response.data.data.folder;
                    }
                    // guardar estado del formulario
                    if(this.stepper == 2) {
                        this.storeState(response.data.data, this.form, this.stepper)
                    }
                })
                .catch(error =>{
                    this.formLoading = false;
                    console.log(error)
                })
        },
        handleNextStep1() {
            this.$refs.formStep1.validate().then(success => {

                if (!success) {
                    return;
                }
                this.stepper = 2;
            });
        },
        handleGenerarAudiencia() {
            this.$refs.formStep2.validate().then(success => {
                if (!success) {
                    return;
                }
                this.submitForm();
            });
        },
        handleBtnFinalizar() {
            this.cleanState();
            this.$emit('onClickSelectStep', STEPS.GO_TO_START);

        },
        handleBtnGenerarNuevaAudiencia() {
            this.cleanState();
            this.$emit('onClickSelectStep', STEPS.GO_TO_EMAIL)
        },
        handleChangeFechaLanzamiento() {
            let date = this.datePickerFechaLanzamiento.split('-')
            this.form.fechaConsulta = `${date[2]}${date[1]}${date[0]}`
        },

        // relacionado a dags
        startWatchingJobSubmited(id) {
            this.jobSubmited = setInterval(this.getJobInfoById, 15000, id)
        },
        stopWatchingJobSubmited() {
            clearInterval(this.jobSubmited)
        },
        onSuccessJobSubmited() {

        },
        getJobInfoById(id) {
            motorAudienciasService
                .getJobInfoById({dag_id: 'dag_motor_comercial',dag_run_id: id})
                .then(response => {
                    console.log(response.data)
                    this.jobState = response.data.data.state 
                    this.jobEndDate = response.data.data.end_date
                    if(this.jobState == 'success') { //si el job termina sin problemas
                        this.onSuccessJobSubmited()
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.stopWatchingJobSubmited()
                })
        },
        storeState({dag_run_id, start_date}, form, step) {
            let data = {
                form_name: 'MOTOR_AUDIENCIAS_EMAIL_COMERCIAL',
                job: {
                    dag_run_id: dag_run_id,
                    dag_id: 'dag_motor_comercial',
                    status: this.jobState,
                    start_date: start_date,
                    end_date: this.jobEndDate,
                },
                form: form,
                step: step
            }
            localStorage.setItem('motoremailcomercial', JSON.stringify(data))
        },
        recoverState() {
            let job = localStorage.getItem('motoremailcomercial')
            return JSON.parse(job)
        },
        cleanState() {
            localStorage.removeItem('motoremailcomercial')
        },
        removeDuplicateItems(arr) {
            return [... new Set(arr)];
        },
        onChangeCategorias() {
            // reset
            this.form.subcategorias = [];
            this.form.clases = [];
            if(this.form.categorias.length > 0) {
                let _filtered = this.categoriasWithSubclasifitations.filter(item =>{ 
                    return this.form.categorias.includes(item.categoria)
                });
                this.inputSubcategoryItems = this.removeDuplicateItems(_filtered.map(item => item.subcategoria)).sort();
                this.inputClaseItems = this.removeDuplicateItems(_filtered.map(item => item.clase)).sort();
            } else {
                this.inputClaseItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.clase) ).sort()
                this.inputSubcategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.subcategoria) ).sort()
            }
        },
        onChangeSubcategorias() {
            // reset
            this.form.clases = [];
            // Filter subcategory by category
            if(this.form.subcategorias.length > 0) {
                let _filtered = this.categoriasWithSubclasifitations.filter(item =>{ 
                    return this.form.subcategorias.includes(item.subcategoria)
                });
                this.inputClaseItems = this.removeDuplicateItems(_filtered.map(item => item.clase)).sort();
            } else {
                this.inputClaseItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.clase) ).sort()
            }
        },
    },
    mounted() {
        // recuperar estado de formulario enviado (por si refrescan la página)
        let state = this.recoverState();
        // si existe un estado de formulario enviado
        if (state && state.form_name == 'MOTOR_AUDIENCIAS_EMAIL_COMERCIAL') {
            // preguntar por el estado del dag enviado en el formulario
            this.getJobInfoById(state.job.dag_run_id)
            // si jobEndDate resulta diferente de null, preguntar por el estado del formulario cada determinado tiempo
            this.startWatchingJobSubmited(state.job.dag_run_id)
            // mandar al paso de descargas si existe un estado de formulario en el local storage
            this.stepper = state.step == 2 ? 3 : state.step; // si se pando el formulario desde paso 3, pasar directo a descarga
            this.form.campNameInt = state.form.campNameInt
            this.form.fechaConsulta = state.form.fechaConsulta
            this.form.similaridad = state.form.similaridad
            this.form.intervaloDias = state.form.intervaloDias
            this.form.propension = state.form.propension
            this.form.excluirCompras = state.form.excluirCompras
            this.form.folder = state.form.folder
            this.form.categorias = state.form.categorias
            this.form.subcategorias = state.form.subcategorias
            this.form.clases = state.form.clases
            this.form.reglasPredefinidas = state.form.reglasPredefinidas
            this.form.flagDividir = state.form.flagDividir
            this.form.por_testigo = state.form.por_testigo
            this.form.flagSembrar = state.form.flagSembrar
            this.form.flagAltas = state.form.flagAltas
            this.form.temporalidades_ordenes_extra = state.form.temporalidades_ordenes_extra
        }


        motorAudienciasService
            .getCategoriesWithSubclasifitacions()
            .then( response => {
                this.categoriasWithSubclasifitations = response.data.data
				this.inputClaseItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.clase) ).sort()
                this.inputCategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.categoria) ).sort()
                this.inputSubcategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.subcategoria) ).sort()
            });
    },
    beforeDestroy() {
        this.stopWatchingJobSubmited();
    },
}
</script>

<style scoped>
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.embed-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>